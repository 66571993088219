import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { Route, Routes } from 'react-router-dom';
import { UserContext } from './UserContext';

import AppTopBar from './AppTopbar';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';

import { Dashboard } from './pages/Dashboard';
import { UnderConstruction } from './pages/UnderConstruction';
import { DeviceList } from './pages/DeviceList';
import { DeviceDetails } from './pages/DeviceDetails';
import { DeviceDashboard } from './pages/DeviceDashboard';
import { Batteries } from './pages/Batteries';
import { SensorDiagnostics } from './pages/SensorDiagnostics';
import { AztDiagnostics } from './pages/AztDiagnostics';
import { MasterCrud } from './pages/MasterCrud';
import { LocationSelect } from './pages/LocationSelect';
import { PortalCrud } from './pages/PortalCrud';
import { HeartbeatDiag } from './pages/HeartbeatDiag';
import { SensorOutage } from './pages/SensorOutage';
import { FlightCompare } from './pages/FlightCompare';
import { ConcessionsDiagnostics } from './pages/ConcessionsDiagnostics';

import PrimeReact from 'primereact/api';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

// Helper to lock the scroll when a modal is open
const useScrollLockOnDialog = () => {
    useEffect(() => {
        const observer = new MutationObserver(() => {
            const isDialogOpen = document.querySelector('.p-component-overlay'); 
            if (isDialogOpen) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
            document.body.style.overflow = '';
        };
    }, []);
}

const App = () => {
    useScrollLockOnDialog();

    const [menuActive, setMenuActive] = useState(false);
    const [menuMode, setMenuMode] = useState('static');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);

    const user = useContext(UserContext);

    const groupAccess = process.env.REACT_APP_GROUP_ACCESS.split(",");

    let menuClick = false;
    let configClick = false;

    const menu = [
        {
            label: '',
            icon: 'pi pi-fw pi-star-o',
            items: [
                { label: "Devices", icon: "pi pi-fw pi-cog", to: "/devices" },
                { label: "Batteries", icon: "pi pi-fw pi-sun", to: "/batteries" },
                { label: "Metadata", icon: "pi pi-fw pi-database", to: "/master-crud" },
                { label: "Locations", icon: "pi pi-fw pi-compass", to: "/location-select" },
                { label: "Portal", icon: "pi pi-fw pi-images", to: "/portal" },
                { label: "Heartbeat", icon: "pi pi-fw pi-heart", to: "/heartbeat" },
                { label: "Device Issues", icon: "pi pi-fw pi-exclamation-triangle", to: "/deviceissues" },
                { label: "Flight Compare", icon: "pi pi-fw pi-list", to: "/flightcompare" },
                { label: "Sensor Diagnostics", icon: "fa-solid fa-stethoscope", to: "/sensordiagnostics" },
                { label: "Storage Table Diagnostics", icon: "fa-solid fa-table", to: "/aztdiagnostics" },
                { label: "Concessions Diagnostics", icon: "fa-solid fa-money-bill-transfer", to: "/concessionsdiagnostics" }
            ]
        }
    ];

    const routers = [
        { path: "/", component: Dashboard, exact: true },
        { path: "/devices", component: DeviceList, exact: true },
        { path: "/sensordiagnostics", component: SensorDiagnostics, exact: true },
        { path: "/aztdiagnostics", component: AztDiagnostics, exact: true },
        { path: "/devices/details/:id?", component: DeviceDetails },
        { path: "/devices/dashboard/:id?", component: UnderConstruction /* DeviceDashboard */ },
        { path: "/batteries", component: Batteries },
        { path: "/master-crud", component: MasterCrud },
        { path: "/location-select", component: LocationSelect },
        { path: "/portal", component: PortalCrud },
        { path: "/heartbeat", component: HeartbeatDiag },
        { path: "/deviceissues", component: SensorOutage },
        { path: "/flightcompare", component: FlightCompare },
        { path: "/concessionsdiagnostics", component: ConcessionsDiagnostics }
    ];

    const onDocumentClick = () => {
        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }
            hideOverlayMenu();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        menuClick = false;
        configClick = false;
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
        }

        if (!event.item.items && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive(prevMenuActive => !prevMenuActive);
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (menuMode === 'overlay') {
            setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
        } else {
            if (isDesktop())
                setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
            else
                setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
        }

        event.preventDefault();
    };

    const onTopbarMenuButtonClick = (event) => {
        hideOverlayMenu();
        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const isDesktop = () => {
        return window.innerWidth > 1024;
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isMenuVisible = () => {
        if (isDesktop()) {
            if (menuMode === 'static')
                return !staticMenuDesktopInactive;
            else if (menuMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        } else {
            if (staticMenuMobileActive)
                return true;
            else if (menuMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        }
    };

    let containerClassName = classNames('layout-wrapper', {
        'menu-layout-static': menuMode !== 'overlay',
        'menu-layout-overlay': menuMode === 'overlay',
        'layout-menu-overlay-active': overlayMenuActive,
        'menu-layout-slim': menuMode === 'slim',
        'menu-layout-horizontal': menuMode === 'horizontal',
        'layout-menu-static-inactive': staticMenuDesktopInactive,
        'layout-menu-static-active': staticMenuMobileActive,
        'p-input-filled': false,
        'p-ripple-disabled': true,
    });

    let menuClassName = classNames('layout-menu-container', { 
        'layout-menu-dark': false, 
        'layout-menu-container-inactive': !isMenuVisible() 
    });

    return (
        <div className={containerClassName} onClick={onDocumentClick}>
            <div className="layout-wrapper-content">
                <AppTopBar 
                    activeUser={user}
                    onMenuButtonClick={onMenuButtonClick}
                    onTopbarMenuButtonClick={onTopbarMenuButtonClick}
                />
			{user?.userInfo?.groups?.some(group => groupAccess.includes(group)) ? (
				<> 
					<div className={menuClassName} onClick={onMenuClick}>
						<div className="menu-scroll-content">
							<AppMenu 
								model={menu}
								menuMode={menuMode}
								active={menuActive}
								onMenuitemClick={onMenuitemClick}
								onRootMenuitemClick={onRootMenuitemClick}
							/>
						</div>
					</div>
					<div className="layout-main">
						<Routes>
							{
								routers.map((router, index) => {
									let routeProps = {};
									if (router.exact) {
										routeProps.exact = true;
									}

									return (
										<Route 
											key={`router${index}`}
											path={router.path}
											element={<router.component />}
											{...routeProps}
										/>
									)
								})
							}
						</Routes>
					</div>

					<div className="layout-mask modal-in"></div>
					<AppFooter />
				</>
                ) : (
					<div className="layout-main" style={{ textAlign: "center", padding: "20px" }}>
						<h1 style={{ fontSize: "20px", fontWeight: "bold", color: "#333", marginTop:"100px" }}>
							Access Denied
						</h1>
						<p style={{ fontSize: "20px", color: "#555" }}>
							Please request access to the Data & Analytics team to proceed.
						</p>
					</div>
				)
			}
            </div>
        </div>
    );
}

export default App;
